/* stylelint-disable */
/* biome-ignore lint/style/noUnknownAtRules: Tailwind directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 220 20% 97%; /* #f5f7fa */
    --foreground: 215 32% 17%; /* #1e2a38 */
    --card: 0 0% 100%;
    --card-foreground: 215 32% 17%;
    --popover: 0 0% 100%;
    --popover-foreground: 215 32% 17%;
    --primary: 217 85% 31%;
    --primary-foreground: 0 0% 100%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 215 32% 17%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215 16% 47%;
    --accent: 43 100% 65%;
    --accent-foreground: 215 32% 17%;
    --destructive: 0 84% 60%;
    --destructive-foreground: 0 0% 100%;
    --border: 214 32% 91%;
    --input: 214 32% 91%;
    --ring: 217 85% 31%;
    --radius: 0.5rem;
    --button-hover: 45 100% 65%;
    --font-sans: var(--font-nunito);
    --font-mono: var(--font-fira-code);
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 217 85% 31%;
    --primary-foreground: 210 40% 98%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 43 100% 65%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 217 85% 31%;
    --button-hover: 45 100% 65%;
  }

  html {
    font-size: 16px; 
  }
  
  body {
    @apply bg-background text-foreground font-medium text-base;
  }
  h1, h2, h3, h4, h5, h6 {
    @apply font-semibold;
  }
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

@layer components {
  .prose {
    @apply text-foreground;
  }
  .prose h1, .prose h2, .prose h3, .prose h4, .prose h5, .prose h6 {
    @apply text-foreground;
  }
  .prose h2 {
    @apply text-xl font-semibold mt-4 mb-2;
  }
  .prose h3 {
    @apply text-lg font-medium mt-3 mb-1;
  }
  .prose h4 {
    @apply text-base font-medium mt-2 mb-1;
  }
  .prose p {
    @apply mb-1;
  }
  .prose ul {
    @apply mb-2 list-disc pl-4;
  }
  .prose li {
    @apply mb-1;
  }
  .prose li p {
    @apply inline;
  }
}
